// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

const userData = localStorage.getItem('userData')
let skin = 'bordered'
if (userData) {
  const userDataObj = JSON.parse(userData)
  if (userDataObj && userDataObj.skin) {
    skin = userDataObj.skin
  }
}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Баскетбольныйцех', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.png'),
    // eslint-disable-next-line global-require
    appLogoImageV: require('@/assets/images/logo/logo.png'),
    // eslint-disable-next-line global-require
    pays: require('@/assets/images/pays/pays.png'),
    // eslint-disable-next-line global-require
    paykeeper: require('@/assets/images/pays/paykeeper.png'),
  },
  layout: {
    isRTL: false,
    skin: skin || 'bordered', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'hidden', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
}
