/* eslint-disable */
// prettier-ignore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login/:id',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/profile/Profile.vue'),
      meta: {
        pageTitle: 'Профиль',
      },
    },
    {
      path: '/confidence',
      name: 'confidence',
      component: () => import('@/views/pages/Confidence.vue'),
      meta: {
        layout: 'full',
      },
    },
    
    {
      path: '/ref/:ref',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    
        {
      path: '/',
      name: 'main',
      component: () => import('@/views/apps/main/Main.vue'),
          },
        {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/apps/partners/Partners.vue'),
            meta: {"pageTitle":"\u041f\u0430\u0440\u0442\u043d\u0435\u0440\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u043f\u0430\u0440\u0442\u043d\u0435\u0440\u043e\u0432","active":true}]},
          },
        {
      path: '/partners/:id/view',
      name: 'partners-view',
      component: () => import('@/views/apps/partners-view/PartnersView.vue'),
            meta: {"pageTitle":"\u041f\u0430\u0440\u0442\u043d\u0435\u0440","breadcrumb":[{"text":"\u041f\u0430\u0440\u0442\u043d\u0435\u0440\u044b","active":false,"to":"\/partners"},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u043f\u0430\u0440\u0442\u043d\u0435\u0440\u0430","active":true}]},
          },
        {
      path: '/partners/:id/edit',
      name: 'partners-edit',
      component: () => import('@/views/apps/partners-edit/PartnersEdit.vue'),
            meta: {"pageTitle":"\u041f\u0430\u0440\u0442\u043d\u0435\u0440","breadcrumb":[{"text":"\u041f\u0430\u0440\u0442\u043d\u0435\u0440\u044b","active":false,"to":"\/partners"},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u0430\u0440\u0442\u043d\u0435\u0440\u0430","active":true}]},
          },
        {
      path: '/superadmins',
      name: 'superadmins',
      component: () => import('@/views/apps/superadmins/Superadmins.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b \u0441\u0438\u0441\u0442\u0435\u043c\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u043e\u0432 \u0441\u0438\u0441\u0442\u0435\u043c\u044b","active":true}]},
          },
        {
      path: '/superadmins/:id/view',
      name: 'superadmins-view',
      component: () => import('@/views/apps/superadmins-view/SuperadminsView.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440 \u0441\u0438\u0441\u0442\u0435\u043c\u044b","breadcrumb":[{"text":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b \u0441\u0438\u0441\u0442\u0435\u043c\u044b","active":false,"to":"\/superadmins"},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0430 \u0441\u0438\u0441\u0442\u0435\u043c\u044b","active":true}]},
          },
        {
      path: '/superadmins/:id/edit',
      name: 'superadmins-edit',
      component: () => import('@/views/apps/superadmins-edit/SuperadminsEdit.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440 \u0441\u0438\u0441\u0442\u0435\u043c\u044b","breadcrumb":[{"text":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b \u0441\u0438\u0441\u0442\u0435\u043c\u044b","active":false,"to":"\/superadmins"},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0430 \u0441\u0438\u0441\u0442\u0435\u043c\u044b","active":true}]},
          },
        {
      path: '/receipttypes',
      name: 'receipttypes',
      component: () => import('@/views/apps/receipttypes/Receipttypes.vue'),
            meta: {"pageTitle":"\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u044f","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0442\u0438\u043f\u043e\u0432 \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u0439","active":true}]},
          },
        {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/apps/roles/Roles.vue'),
            meta: {"pageTitle":"\u0420\u043e\u043b\u0438"},
          },
        {
      path: '/(\\d+)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[0-9]{1,}[a-zA-Z]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

if (localStorage.getItem('userData')) {
  
  
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters['app/isLoggedIn'] && localStorage.getItem('accessToken') && localStorage.getItem('userData')) {
    store.commit('app/SET_USER', JSON.parse(localStorage.getItem('userData')))
    store.commit('app/SET_LOGGED_IN', true)
  }

  if (!localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path !== '/login') {
    if (to.matched[0].path === '/signup') {
      next()
    } else if (to.matched[0].path === '/verify-login/:id') {
      next()
    } else if (to.matched[0].path === '/ref/:ref') {
      next()
    }
    else if (to.matched[0].path === '/verify-login') {
      next()
    }
    else if (to.matched[0].path === '/(\\d+)') {
      next()
    }
    else if (to.matched[0].path === '/(\[a-zA-Z]{1,}[0-9]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/(\[0-9]{1,}[a-zA-Z]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/(\[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/(\[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/(\[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/(\[0-9]{1,}[a-zA-Z]{1,}[0-9]{1,}[a-zA-Z]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/reset-password') {
      next()
    } else if (to.matched[0].path === '/confidence') {
      next()
    }
    else if (to.matched[0].path === '/confidenceBinar') {
      next()
    }
    else if (to.matched[0].path === '/forgot-password') {
      next()
    } else if (to.matched[0].path === '/report-form/:id') {
      next()
    } else if (to.matched[0].path === '/report-form/:id/:channel') {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  }

  if (localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path === '/login') {
    next({
      path: '/',
    })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
