import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'http://api.bc-sport.ru/api/',
})

axiosIns.interceptors.request.use(config => {
  if (config.params) {
    let params = { ...config.params }
    // eslint-disable-next-line
    for (const param in params) {
      if (params[param] && (typeof params[param] === 'string' || params[param] instanceof String)) {
        if (params[param] !== '--1' && params[param] !== '--2') {
          const parts = params[param].split('-')
          if (parts && parts.length > 2) {
            // eslint-disable-next-line no-param-reassign
            params[param] = `${parts[2]}.${parts[1]}.${parts[0]}`
          }
        }
      }
    }

    params = new URLSearchParams(JSON.parse(JSON.stringify(params))).toString()
    if (config.url.indexOf('?') > -1) {
      // eslint-disable-next-line no-param-reassign
      config.url += `&${params}`
    } else {
      // eslint-disable-next-line no-param-reassign
      config.url += `?${params}`
    }
  }

  // eslint-disable-next-line no-param-reassign
  delete config.params

  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
